var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"page-container"},[_c('div',{staticClass:"container-title-block"},[_c('div',{staticClass:"title-block-text"},[_vm._v("Баннеры")]),_c('v-btn',{staticClass:"button-blue",attrs:{"depressed":""},on:{"click":_vm.openDialog}},[_vm._v(" Добавить баннер ")])],1),_c('div',{staticClass:"container-subtitle-block"}),_c('div',{staticClass:"container-table"},[_c('Table',{attrs:{"items":_vm.itemsAll,"headers":_vm.headers,"draggable":"","headerFixed":""},on:{"click:draggable":_vm.newSort,"click:row":_vm.openDialog},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"table-icon",attrs:{"src":item.image}})]}}],null,true)})],1),_c('Dialog',{attrs:{"refForm":"testForm","titleText":_vm.dialogItems.headerText,"mainItems":[
      {
        type: 'text',
        label: 'Текст',
        value: this.selectedItem.body,
        vmodel: function (e) { return (this$1.selectedItem.body = e); },
        valid: true,
      },
      {
        type: 'image',
        label: 'Изображение',
        value: this.selectedItem.image,
        vmodel: function (e) { return (this$1.selectedItem.image = e); },
        valid: true,
      },
      {
        type: 'text',
        label: 'Ссылка',
        valid: true,
        value: this.selectedItem.link,
        vmodel: function (e) { return (this$1.selectedItem.link = e); },
      } ],"buttonsFooter":_vm.dialogItems.buttonsFooter},on:{"click:success":_vm.sendItem,"click:delete":_vm.deleteItem},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }