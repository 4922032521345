  
<template>
  <v-autocomplete
    :items="items"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change')"
    :label="label"
    :rules="checkRules"
    color="var(--app-blue)"
    :item-text="itemText"
    :item-title="itemTitle"
    :item-value="itemValue"
    :clearable="clearable"
    outlined
    :multiple="multiple"
    :small-chips="multiple"
    deletable-chips
    :disabled="disabled ? (value ? true : false) : false"
    :hide-details="hideDetails"
  >
  </v-autocomplete>
</template>
<script>

export default {
  props: {
    label: { type: String, default: "" },
    itemText: {},
    itemTitle: { type: String, default: "name" },
    itemValue: { type: String, default: "id" },
    items: {
      type: Array || Object,
      default() {
        return [];
      },
    },
    value: {},
    valid: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    changeText: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    smallChips: { type: Boolean, default: false },
  },
  data: () => ({}),
  methods: {},
  computed: {
    checkRules() {
      if (this.valid) {
        return [(value) => !!value || "Обязательно к заполнению"];
      }
      return [];
    },
  },
  mounted() {},
  created() {},
  watch: {},
};
</script>
<style >
.theme--light.v-select .v-select__selection--disabled {
  color: rgba(0, 0, 0, 1) !important;
}
</style>
