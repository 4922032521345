<template>
  <div class="switch-block">
    <div class="switch-block-text-box">
      <v-icon v-if="icon" color="var(--app-grey-3)" size="24">
        {{ icon }}
      </v-icon>
      <div class="text-box-text">
        {{ label }}
      </div>
    </div>
    <v-switch v-model="bool" :disabled="disabled" :model-value="modelValue" hide-details color="var(--app-green)"></v-switch>
  </div>
</template>
<script>
export default {
  props: {
    label: { type: String, default: "" },
    value: { type: Boolean, default: false },
    icon: {},
    disabled: {type: Boolean, default: false},
    modelValue: {type: Boolean, default: false},
  },
  data: () => ({}),
  methods: {},
  created() {},
  computed: {
    bool: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
        this.$emit("input", value);
      },
    },
  },
  mounted() {},

  watch: {},
};
</script>
<style scoped lang="scss">
.switch-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--app-separators);
  border-radius: 6px;
  padding: 0px 12px;
  margin-bottom: 20px;
  height: 45px;
  .switch-block-text-box {
    display: flex;
    align-items: center;
    i {
      margin-right: 10px;
    }
    .text-box-text {
      font-weight: 500;
    }
  }
}
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
